.Home .lander {
  padding: 80px 0;
  text-align: center;
}

.Home .lander h1 {
  font-weight: 600;
}

.Home .lander p {
  color: #999;
}

.Home .rebuild-section {
  text-align: center;
}
