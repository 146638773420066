.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

.App .nav-item {
  color: rgba(0, 0, 0, 0.5);
}
.App .nav-item.active {
  color: rgba(0, 0, 0, 0.9);
}
